import React from 'react';

const BlogPageDecor = props => {

  return (
    <section className="blog-page-decor">
        <div className="blog-page-top-image"></div>
    </section>
  )
};

export default BlogPageDecor;
