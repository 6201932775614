import React from "react";
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout";
import BlogPageDecor from "../components/BlogPageDecor";

export default function About({ data }) {
  return (
    <Layout>
       <BlogPageDecor/>
      <h1>
        Blog Posts
      </h1>
      <h4>{data.allMarkdownRemark.totalCount} Posts</h4>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <div key={node.id}>
          <Link
              to={node.fields.slug}
           >
          <h3>
            {node.frontmatter.title}{" "}
          </h3>
          </Link>
          <p>{node.excerpt}</p>
         
        </div>
      ))}
     
    </Layout>
  )
}
export const query = graphql`
  query {
    allMarkdownRemark {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
